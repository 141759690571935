import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Navbar from "./components/Navbar";
import Leaderboard from "./pages/Leaderboard";
import { auth } from "./firebase";
import { signInWithCustomToken } from "firebase/auth";
import { NavbarProvider, useNavbarContext } from "./context/NavbarContext";
import './App.css'; // Or replace with your global CSS file path


const App = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const authenticateWithFirebase = async (firebaseToken) => {
        try {
            const userCredential = await signInWithCustomToken(auth, firebaseToken);
            setUser(userCredential.user);
        } catch (error) {
            console.error("Error authenticating with Firebase:", error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const firebaseToken = urlParams.get("firebaseToken");

        if (firebaseToken) {
            window.history.replaceState({}, document.title, window.location.pathname);
            authenticateWithFirebase(firebaseToken);
        } else {
            setLoading(false);
        }
    }, []);

    const handleLogout = () => {
        auth.signOut()
            .then(() => {
                setUser(null);
                console.log("User logged out successfully.");
            })
            .catch((error) => {
                console.error("Error during logout:", error.message);
            });
    };

    if (loading) {
        return <div style={{ textAlign: "center", marginTop: "50px" }}>Loading...</div>;
    }

    return (
        <NavbarProvider>
            <AppWithNavbar user={user} onLogout={handleLogout} />
        </NavbarProvider>
    );
};

const AppWithNavbar = ({ user, onLogout }) => {
    const location = useLocation();
    const { collapsed } = useNavbarContext();

    useEffect(() => {
        if (location.pathname === "/") {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [location]);

    const isHomePage = location.pathname === "/"; // Check if the current route is the Home page

    return (
        <div className={`app-container ${isHomePage ? "no-navbar" : "with-navbar"}`}>
            {/* Conditionally render the Navbar */}
            {!isHomePage && <Navbar user={user} onLogout={onLogout} />}
            <div className={`main-content ${!isHomePage && collapsed ? "collapsed" : ""}`}>
                <Routes>
                    <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Home />} />
                    <Route path="/dashboard" element={user ? <Dashboard user={user} /> : <Navigate to="/" />} />
                    <Route path="/leaderboard" element={user ? <Leaderboard /> : <Navigate to="/" />} />
                </Routes>
            </div>
        </div>
    );
};

export default App;
