// frontend/src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// Set up a base URL for API requests
// The URL should come from an environment variable (secure and configurable)
const API_BASE_URL =
    process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

// Make API_BASE_URL globally accessible (optional, only for testing; not recommended in production)
window.API_BASE_URL = API_BASE_URL;

// Create the root for ReactDOM
const root = ReactDOM.createRoot(document.getElementById('root'));

// Wrap App in BrowserRouter to enable routing
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);

// Log performance metrics (optional)
reportWebVitals();
