import React, { createContext, useState, useContext } from "react";

export const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <NavbarContext.Provider value={{ collapsed, setCollapsed }}>
            {children}
        </NavbarContext.Provider>
    );
};

// Create a custom hook for using NavbarContext
export const useNavbarContext = () => {
    const context = useContext(NavbarContext);
    if (!context) {
        throw new Error("useNavbarContext must be used within a NavbarProvider");
    }
    return context;
};
