import React, { useRef, useEffect, useState } from "react";
import "./../styles/Home.css"; // Adjusted CSS imported here

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const Home = ({ onLogin }) => {
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [showModal, setShowModal] = useState(true); // Popup modal state
    const [showTermsModal, setShowTermsModal] = useState(false); // Terms Modal state
    const [showPrivacyModal, setShowPrivacyModal] = useState(false); // Privacy Modal state

    const handleLogin = () => {
        console.log("Login triggered");
        window.location.href = `${BACKEND_URL}/auth/roblox`; // Backend login endpoint
    };

    const handleAudioToggle = () => {
        const audio = audioRef.current;
        if (audio) {
            if (isAudioPlaying) {
                audio.pause();
                setIsAudioPlaying(false);
            } else {
                audio
                    .play()
                    .then(() => setIsAudioPlaying(true))
                    .catch((err) => console.warn("Audio playback failed:", err.message));
            }
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleTermsClose = () => setShowTermsModal(false);
    const handlePrivacyClose = () => setShowPrivacyModal(false);

    return (
    <div className="home-container">
        {/* Popup Modal */}
        {showModal && (
            <div className="modal-overlay" onClick={handleModalClose}>
                <div className="modal-content">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/romax-d8852.firebasestorage.app/o/2.png?alt=media&token=ea7ab252-9085-41b9-973b-b0e1755139d1"
                        alt="Welcome"
                        className="modal-image"
                    />
                    <button className="modal-close-button" onClick={handleModalClose}>
                        &#x2715;
                    </button>
                    <p className="modal-click-to-continue">Click anywhere to continue</p>
                </div>
            </div>
        )}

        {/* Video Background */}
        <div className="video-wrapper">
            <video
                ref={videoRef}
                className="background-video"
                autoPlay
                loop
                muted
                playsInline
                src="https://firebasestorage.googleapis.com/v0/b/romax-d8852.firebasestorage.app/o/Giga%20Chad%20but%20in%20Roblox_720.mp4?alt=media&token=ee11cbbf-710c-49e4-98f6-cd33c5804a5b"
            />
        </div>

        {/* Audio Element */}
        <audio
            ref={audioRef}
            loop
            src="https://firebasestorage.googleapis.com/v0/b/romax-d8852.firebasestorage.app/o/alpha.mp3?alt=media&token=b4a81842-5b1e-4329-a7ae-0bbe46545d5f"
        ></audio>

        {/* Audio Control Icon */}
        <div className="audio-icon" onClick={handleAudioToggle}>
            {isAudioPlaying ? <span>&#x1F507;</span> : <span>&#x1F3B5;</span>}
        </div>


            {/* Home Content */}
            <div className="home-content">
                <h1 className="home-title">
                    Welcome to Ro<span className="gradient-text">MAX</span>
                </h1>
                <p className="waitlist-potential">
                    <span className="gradient-text">MAX</span> Your Looks and Roblox Potential
                </p>

                <button className="home-login-button" onClick={handleLogin}>
                    Login with Roblox
                </button>
            </div>

            {/* Terms and Privacy Policy Links */}
            <p className="home-agreement">
                By using RoMAX, you agree to the following:
                <br />
                <span className="clickable-text" onClick={() => setShowTermsModal(true)}>
                    Terms
                </span>{" "}
                and{" "}
                <span className="clickable-text" onClick={() => setShowPrivacyModal(true)}>
                    Privacy Policy
                </span>
            </p>

            {/* Terms and Conditions Modal */}
            {showTermsModal && (
                <div
                    className="terms-modal-overlay"
                    onClick={(e) => {
                        if (e.target.classList.contains("terms-modal-overlay")) {
                            handleTermsClose(); // Close if clicked outside the modal
                        }
                    }}
                >
                    <div className="terms-modal-content" onClick={(e) => e.stopPropagation()}>
                        <button
                            className="terms-modal-close-button"
                            onClick={handleTermsClose}
                        >
                            &#x2715;
                        </button>
                        <h2 className="terms-modal-title">Terms and Conditions</h2>
                        <div className="terms-modal-body">
                            <h3>Welcome to RoMAX!</h3>
                            <p>
                                Thank you for joining the waitlist. By signing up, you agree to the following terms and conditions:
                            </p>

                            <h4>1. Eligibility</h4>
                            <p>
                                You must be at least 13 years old to join the RoMAX waitlist. By signing up, you confirm that all information you provide (e.g., email address and Roblox username) is accurate and belongs to you.
                            </p>

                            <h4>2. Purpose of the Waitlist</h4>
                            <p>
                                The waitlist grants early access notifications and updates regarding the official launch of RoMAX. Joining the waitlist does not guarantee access to the final product.
                            </p>

                            <h4>3. Data Collection</h4>
                            <p>
                                By submitting your email and Roblox username, you consent to the collection of this data for the purposes of:
                                <ul>
                                    <li>Sending updates about RoMAX, including promotions, announcements, and early access opportunities.</li>
                                    <li>Personalizing your experience with RoMAX, including leaderboards or user-specific features.</li>
                                </ul>
                            </p>

                            <h4>4. Communications</h4>
                            <p>
                                You agree to receive communications from us via email. You may unsubscribe at any time by following the "Unsubscribe" link in our emails.
                            </p>

                            <h4>5. Prohibited Use</h4>
                            <p>
                                You must not attempt to abuse, disrupt, or harm the waitlist system in any way. Providing false information or using automated tools to manipulate waitlist positions will result in removal.
                            </p>

                            <h4>6. Termination</h4>
                            <p>
                                We reserve the right to terminate your access to the waitlist at our discretion if we find a violation of these terms.
                            </p>

                            <h4>7. Privacy Policy</h4>
                            <p>
                                At RoMAX, we respect your privacy and are committed to protecting your personal information. We will:
                                <ul>
                                    <li>Use your email and Roblox username to provide updates and personalized experiences.</li>
                                    <li>Never sell, rent, or share your personal data with third parties without your consent.</li>
                                </ul>
                                For more details, please review our Privacy Policy.
                            </p>

                            <h4>8. Modifications</h4>
                            <p>
                                These terms are subject to change. Updated terms will be posted, and your continued participation in the waitlist constitutes agreement to the changes.
                            </p>

                            <h4>9. Contact</h4>
                            <p>
                                For any questions regarding these terms, please contact us at <strong>contact@romax.gg</strong>.
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {/* Privacy Policy Modal */}
            {showPrivacyModal && (
                <div
                    className="terms-modal-overlay"
                    onClick={(e) => {
                        if (e.target.classList.contains("terms-modal-overlay")) {
                            handlePrivacyClose(); // Close if clicked outside the modal
                        }
                    }}
                >
                    <div className="terms-modal-content" onClick={(e) => e.stopPropagation()}>
                        <button
                            className="terms-modal-close-button"
                            onClick={handlePrivacyClose}
                        >
                            &#x2715;
                        </button>
                        <h2 className="terms-modal-title">Privacy Policy</h2>
                        <div className="terms-modal-body">
                            <p>
                                **Effective Date:** December 16th, 2024
                            </p>
                            <p>
                                At <strong>RoMAX</strong>, your privacy is our top priority.
                                This Privacy Policy outlines how we collect, use, protect, and
                                handle your personal information when you join our waitlist
                                or interact with our services.
                            </p>

                            <h3>1. Information We Collect</h3>
                            <p>
                                We collect the following personal information when you voluntarily provide it:
                                <ul>
                                    <li><strong>Email Address</strong>: To communicate updates, launch notifications, and promotional materials.</li>
                                    <li><strong>Roblox Username</strong>: To personalize your experience and validate your participation.</li>
                                </ul>
                            </p>

                            <h3>2. How We Use Your Information</h3>
                            <p>
                                We use the collected information for the following purposes:
                                <ul>
                                    <li><strong>To keep you informed</strong>: Send updates, newsletters, and notifications about RoMAX.</li>
                                    <li><strong>To improve our services</strong>: Analyze user interest and engagement.</li>
                                    <li><strong>To personalize content</strong>: Tailor messages and features based on user preferences.</li>
                                </ul>
                                We do <strong>not</strong> sell, trade, or rent your information to third parties.
                            </p>

                            <h3>3. Sharing Your Information</h3>
                            <p>
                                We may share your information with:
                                <ul>
                                    <li><strong>Service Providers</strong>: Third-party tools we use to operate RoMAX (e.g., email delivery services). These providers are contractually obligated to protect your data.</li>
                                    <li><strong>Legal Requirements</strong>: If required to comply with legal obligations, regulations, or requests from government authorities.</li>
                                </ul>
                            </p>

                            <h3>4. Data Security</h3>
                            <p>
                                We take reasonable steps to protect your personal information. This includes:
                                <ul>
                                    <li><strong>Encryption</strong>: Securing data during storage and transmission.</li>
                                    <li><strong>Restricted Access</strong>: Limiting access to personal information to authorized personnel only.</li>
                                    <li><strong>Third-Party Standards</strong>: Partnering with trusted platforms to safeguard your data.</li>
                                </ul>
                                While we strive to protect your data, no method of transmission over the internet is 100% secure.
                            </p>

                            <h3>5. Your Rights</h3>
                            <p>
                                You have the following rights regarding your information:
                                <ul>
                                    <li><strong>Access</strong>: Request a copy of the data we have about you.</li>
                                    <li><strong>Correction</strong>: Update any incorrect or incomplete data.</li>
                                    <li><strong>Deletion</strong>: Request the deletion of your personal data.</li>
                                    <li><strong>Opt-out</strong>: Unsubscribe from promotional communications.</li>
                                </ul>
                                To exercise these rights, contact us at <strong>contact@romax.gg</strong>.
                            </p>

                            <h3>6. Cookies and Tracking Technologies</h3>
                            <p>
                                RoMAX may use cookies or similar technologies to:
                                <ul>
                                    <li>Improve user experience.</li>
                                    <li>Analyze usage patterns on our website.</li>
                                </ul>
                                You can disable cookies in your browser settings if you prefer.
                            </p>

                            <h3>7. Changes to this Privacy Policy</h3>
                            <p>
                                We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements.
                                If we make significant changes, we will notify you via email or post a notice on our website.
                            </p>

                            <h3>8. Contact Us</h3>
                            <p>
                                If you have any questions about this Privacy Policy or our data practices, please contact us at:
                                <br />
                                <strong>RoMAX Team</strong><br />
                                Email: <strong>contact@romax.gg</strong>
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {/* Floating Join Image */}
            {!showModal && (
                <div className="floating-join-image" onClick={() => setShowModal(true)}>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/romax-d8852.firebasestorage.app/o/Daemon%20Dagger.png?alt=media&token=1df4a156-072a-4311-813e-7446c9bac2b0"
                        alt="Join to Win"
                        className="floating-image"
                    />
                    <p className="floating-join-text">Join to Win</p>
                </div>
            )}
        </div>
    );
};

export default Home;
