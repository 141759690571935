import React, { useContext, useState, useEffect } from "react";
import "../styles/Leaderboard.css";
import axios from "axios";
import { NavbarContext } from "../context/NavbarContext"; // Import NavbarContext

const Leaderboard = ({ user }) => {
    const { collapsed } = useContext(NavbarContext); // Use NavbarContext
    const [leaders, setLeaders] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const fetchLeaderboard = async () => {
            try {
                const leaderboardResponse = await axios.get("http://localhost:5000/leaderboard");
                setLeaders(leaderboardResponse.data);

                if (user) {
                    const currentUserResponse = await axios.get(
                        `http://localhost:5000/roblox-userinfo?userId=${user.uid}`
                    );
                    setCurrentUser(currentUserResponse.data);
                }
            } catch (error) {
                console.error("Error fetching leaderboard or user info:", error.message);
            }
        };

        fetchLeaderboard();
    }, [user]);

    return (
        <div className={`leaderboard ${collapsed ? "collapsed" : ""}`}>
            <h1>Leaderboard</h1>
            <table className="leaderboard-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Username</th>
                        <th>Points</th>
                        <th>Rank</th>
                    </tr>
                </thead>
                <tbody>
                    {leaders.map((leader, index) => (
                        <tr key={leader.userId || index}>
                            <td>{index + 1}</td>
                            <td>{leader.username}</td>
                            <td>{leader.points}</td>
                            <td>{leader.rank}</td>
                        </tr>
                    ))}
                    {currentUser && (
                        <>
                            <tr key="divider">
                                <td colSpan="4" className="divider-row">
                                    Your Stats
                                </td>
                            </tr>
                            <tr key="current-user">
                                <td>
                                    {leaders.findIndex((leader) => leader.userId === user.uid) + 1 || "N/A"}
                                </td>
                                <td>{currentUser.username}</td>
                                <td>{currentUser.points}</td>
                                <td>{currentUser.rank}</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Leaderboard;
