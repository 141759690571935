import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../styles/Navbar.css";
import { NavbarContext } from "../context/NavbarContext"; // Import NavbarContext
import axios from "axios";

const Navbar = ({ user, onLogout }) => {
    const { collapsed, setCollapsed } = useContext(NavbarContext); // Use NavbarContext
    const [userInfo, setUserInfo] = useState({ username: "", profilePicture: "" });
    const [loading, setLoading] = useState(true);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false); // Add state for logout confirmation modal

    const fetchUserInfo = async () => {
        try {
            const response = await axios.get(`http://localhost:5000/roblox-userinfo?userId=${user.uid}`);
            const userData = response.data;

            setUserInfo({
                username: userData.username || "Unknown User",
                profilePicture: userData.profilePicture || "",
            });
        } catch (error) {
            console.error("Error fetching user info:", error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            fetchUserInfo();
        }
    }, [user]);

    const handleToggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className="navbar-container">
            {/* Sidebar */}
            <nav className={`navbar ${collapsed ? "collapsed" : ""}`}>
                {/* Logo */}
                <div className={`navbar-logo ${collapsed ? "hidden" : ""}`}>
                    Ro<span className="gradient-text">MAX</span>
                </div>

                {/* Links */}
                <div className="navbar-links">
                    <NavLink to="/dashboard" className="navbar-link">
                        <span className="emoji-icon">🧍</span> {!collapsed && "Dashboard"}
                    </NavLink>
                    <NavLink to="/activities" className="navbar-link">
                        <span className="emoji-icon">⚡</span> {!collapsed && "Activities"}
                    </NavLink>
                    <NavLink to="/leaderboard" className="navbar-link">
                        <span className="emoji-icon">🏆</span> {!collapsed && "Leaderboard"}
                    </NavLink>
                    <NavLink to="/tips" className="navbar-link">
                        <span className="emoji-icon">💬</span> {!collapsed && "Tips"}
                    </NavLink>
                    <NavLink to="/goals" className="navbar-link">
                        <span className="emoji-icon">🎯</span> {!collapsed && "Goals"}
                    </NavLink>
                </div>

                {/* Account Section */}
                <div className="navbar-account" onClick={() => setShowLogoutConfirm(true)}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div className="navbar-profile">
                            {/* Profile Picture */}
                            <img
                                src={userInfo.profilePicture}
                                alt="Profile"
                                className="navbar-profile-pic"
                            />
                            {/* Username (Hidden in Collapsed State) */}
                            {!collapsed && (
                                <span className="navbar-username">{userInfo.username}</span>
                            )}
                        </div>
                    )}
                </div>

                {/* Logout Confirmation Modal */}
                {showLogoutConfirm && (
                    <div className="logout-modal-overlay" onClick={() => setShowLogoutConfirm(false)}>
                        <div className="logout-modal" onClick={(e) => e.stopPropagation()}>
                            <h3>Confirm Logout</h3>
                            <p>Are you sure you want to sign out?</p>
                            <div className="logout-modal-buttons">
                                <button
                                    className="cancel-button"
                                    onClick={() => setShowLogoutConfirm(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="confirm-button"
                                    onClick={onLogout}
                                >
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </nav>

            {/* Toggle Button */}
            <button
                className="toggle-button"
                onClick={handleToggleSidebar}
                aria-label="Toggle Sidebar"
            >
                {collapsed ? ">" : "<"}
            </button>
        </div>
    );
};

export default Navbar;
