import React, { useContext, useEffect, useState } from "react";
import "../styles/Dashboard.css"; // Importing the styles
import axios from "axios";
import { NavbarContext } from "../context/NavbarContext"; // Import NavbarContext

const Dashboard = ({ user }) => {
    const { collapsed } = useContext(NavbarContext); // Use NavbarContext
    const [inventory, setInventory] = useState([]); // Initialize as an empty array
    const [score, setScore] = useState(0);
    const [loading, setLoading] = useState(true); // Start loading as true
    const [isInitialized, setIsInitialized] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [avatar3DUrl, setAvatar3DUrl] = useState(""); // For storing full body avatar URL

    const fetchFirestoreData = async () => {
        try {
            console.log("Fetching user data from Firestore...");
            const response = await axios.get(`http://localhost:5000/roblox-userinfo?userId=${user.uid}`);
            const userData = response.data;

            console.log("Fetched Firestore Data:", userData);

            // Set user info
            setUserInfo({
                nickname: userData.username || "Roblox User",
            });

            // Set score
            setScore(userData.points || 0);

            // Set inventory categories
            if (userData.inventory) {
                const { assets = [], badges = [], gamePasses = [] } = userData.inventory;
                setInventory({
                    assets,
                    badges,
                    gamePasses,
                });
            } else {
                console.warn("No inventory found for user.");
                setInventory({
                    assets: [],
                    badges: [],
                    gamePasses: [],
                });
            }

            // Set full body avatar URL from Firestore
            if (userData.profilePictureFull) {
                setAvatar3DUrl(userData.profilePictureFull);
            }

            // Check initialization status
            if (userData.isInitialized) {
                console.log("User is already initialized.");
                setIsInitialized(true);
            } else {
                console.log("User not initialized. Initializing...");
                const initResponse = await axios.post(`http://localhost:5000/initialize-user`, {
                    userId: user.uid,
                });

                if (initResponse.data.success) {
                    console.log("User initialized successfully.");
                    setIsInitialized(true);

                    // Fetch updated inventory after initialization
                    const updatedResponse = await axios.get(`http://localhost:5000/roblox-userinfo?userId=${user.uid}`);
                    const updatedData = updatedResponse.data;

                    if (updatedData.inventory) {
                        const { assets = [], badges = [], gamePasses = [] } = updatedData.inventory;
                        setInventory({
                            assets,
                            badges,
                            gamePasses,
                        });
                    }

                    if (updatedData.profilePictureFull) {
                        setAvatar3DUrl(updatedData.profilePictureFull);
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching Firestore data:", error.message);
        } finally {
            setLoading(false); // Stop loading after data is fetched
        }
    };

    useEffect(() => {
        if (user) {
            fetchFirestoreData();
        }
    }, [user]);

    return (
        <div className="dashboard">
            {/* Score Header */}
            <div className="score-header">
                <h2>RoMAX Score {score}</h2>
                <div className="inventory-summary">
                    <span className="summary-item" title="Badges">
                        <i className="icon">🏅</i> {inventory.badges?.length || 182}
                    </span>
                    <span className="summary-item" title="Game Passes">
                        <i className="icon">📦</i> {inventory.gamePasses?.length || 46}
                    </span>
                    <span className="summary-item" title="UGC">
                        <i className="icon">👕</i> {inventory.assets?.length || 223}
                    </span>
                    <span className="summary-item" title="Potential">
                        <i className="icon">🧍</i> {97} {/* Static value for potential */}
                    </span>
                </div>
            </div>

            <div className="dash-content">
                {/* Avatar Section */}
                <div className="avatar-container">
                    {loading ? (
                        <p>Loading Avatar...</p>
                    ) : (
                        <img src={avatar3DUrl} alt="User Avatar" className="avatar-image" />
                    )}
                    <p className="rank-label">
                        Rank
                    </p>
                </div>

                <div className="profile-section">
                    {/* Profile Analysis */}
                    <div className="profile-analysis">
                        <h3>Profile Analysis</h3>
                        <p>Bacon-haired noob with untapped potential and a smile that says "soon™".</p>
                    </div>

                    {/* MAX Activities */}
                    <div className="max-activities">
                        <h3>MAX Activities</h3>
                        <div className="activity-item">
                            <button className="activity-btn">Go</button>
                            <p>Collect more UGC Limiteds</p>
                            <span className="activity-points">
                                <span className="arrow">▲</span> 20
                            </span>
                        </div>
                        <div className="activity-item">
                            <button className="activity-btn">Go</button>
                            <p>Improve your Rizz</p>
                            <span className="activity-points">
                                <span className="arrow">▲</span> 20
                            </span>
                        </div>
                        <div className="activity-item">
                            <button className="activity-btn">Go</button>
                            <p>Purchase and wear new hair</p>
                            <span className="activity-points">
                                <span className="arrow">▲</span> 100
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <div className="footer-content">
                <div className="what-others-say">
                    <h3>What Others Are Saying</h3>
                    <p>You could use a new hairstyle...</p>
                    <button className="see-more-btn">See More</button>
                </div>
                <div className="profile-ratings">
                    <h3>Profile Ratings 200</h3>
                    <div className="ratings-stars">★★★★★</div>
                    <button className="more-btn">More</button>
                </div>
            </div>
        </div>

    );
};

export default Dashboard;
